












































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { vxm } from '@/store'

class PaymentTypeXRoundingMode {
  id: number
  paymentTypeId: string
  roundingModeId: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = (data.id as number) || null
    this.paymentTypeId = (data.paymentTypeId as string) || null
    this.roundingModeId = (data.roundingModeId as string) || null
  }
}

@Component({
  components: { ListView },
})
export default class Edit extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  private headers = []
  private rowActions = []

  private paymentTypes = []
  private filteredPaymentTypes = []
  private roundingModes = []
  private existingPaymentTypeIds = []

  private dialogPaymentTypeXRoundingMode: PaymentTypeXRoundingMode = new PaymentTypeXRoundingMode()

  private valid = true
  private dialog = false
  private editDialog = false
  private rules = {}

  private listViewKey = 0

  private created() {
    this.headers = [
      {
        text: 'Payment type',
        value: 'paymentTypeId',
        sortable: false,
      },
      {
        text: 'Rounding mode',
        value: 'roundingModeId',
        sortable: false,
      },
      {
        text: 'Is default?',
        value: 'isDefault',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.rowActions = [
      {
        id: 'edit',
        click: (_, item) => {
          this.dialogPaymentTypeXRoundingMode = JSON.parse(JSON.stringify(item))
          this.openDialog(true)
        },
      },
      {
        id: 'delete',
        visibility: (item) => {
          return item.paymentTypeId
        },
      },
    ]

    this.rules = {
      paymentTypeId: [(v) => !!v || this.$t('c:validation:This field is required')],
      roundingModeId: [(v) => !!v || this.$t('c:validation:This field is required')],
    }

    this.$axios.get('/v4/site/payment/payment-types').then((response) => {
      this.paymentTypes = response.data.data
    })

    this.$axios.get('/v4/site/payment/rounding-modes').then((response) => {
      this.roundingModes = response.data.data
    })
  }

  private get url() {
    return '/v4/site/payment-type/rounding-modes'
  }

  private save() {
    if (this.$refs.form.validate()) {
      if (this.editDialog) {
        this.$axios
          .put(
            '/v4/site/payment-type/rounding-modes/' + this.dialogPaymentTypeXRoundingMode.id,
            this.dialogPaymentTypeXRoundingMode,
          )
          .then(() => {
            this.listViewKey += 1
            this.dialog = false
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err, 'Could not copy auto-booking')
          })
      } else {
        this.$axios
          .post('/v4/site/payment-type/rounding-modes', this.dialogPaymentTypeXRoundingMode)
          .then(() => {
            this.listViewKey += 1
            this.dialog = false
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err, 'Could not copy auto-booking')
          })
      }
    }
  }

  private openDialog(editMode = false) {
    if (!editMode) {
      this.dialogPaymentTypeXRoundingMode = new PaymentTypeXRoundingMode()
    }
    this.editDialog = editMode
    this.dialog = true
  }

  public onData(data): void {
    this.existingPaymentTypeIds = data.items.map((paymentTypeXRoundingMode) => paymentTypeXRoundingMode.paymentTypeId)
    this.filteredPaymentTypes = this.paymentTypes.filter(
      (paymentType) => !this.existingPaymentTypeIds.includes(paymentType.value),
    )
  }
}
